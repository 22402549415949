import { render, staticRenderFns } from "./Body.vue?vue&type=template&id=faa36c40"
import script from "./Body.vue?vue&type=script&lang=js"
export * from "./Body.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonLoader: require('/home/runner/work/tomasu-website/tomasu-website/app/components/skeletons/SkeletonLoader.vue').default})
