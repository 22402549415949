
import { string } from 'vue-types';
import { customLinkArray } from '~/types/customLink';

export default {
  props: {
    body: string(),
    link: customLinkArray,
  },
  computed: {
    linkProps() {
      const link = this.$first(this.link);

      if (!link) {
        return null;
      }

      return {
        ...link,
        label: link.label ? link.label : this.$first(link.locationEntry) ? this.$first(link.locationEntry).title : '',
      };
    },
  },
};
