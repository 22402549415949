
import { string, bool, oneOf } from 'vue-types';

export default {
  props: {
    content: string(),
    loading: bool().def(false),
    tag: oneOf(['span', 'div', 'p']).def('span'),
  },
};
