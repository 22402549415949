
import { number, string } from 'vue-types';

export default {
  props: {
    // The default maxiumum width is 100%.
    maxWidth: number().def(100),
    // Lines have a minimum width of 80%.
    minWidth: number().def(80),
    // Make lines the same height as text.
    height: string().def('1em'),
    // Make it possible to define a fixed
    // width instead of using a random one.
    width: string(),
  },
  computed: {
    computedWidth() {
      // Either use the given fixed width or
      // a random width between the given min
      // and max values.
      return this.width || `${Math.floor(Math.random() * (this.maxWidth - this.minWidth) + this.minWidth)}%`;
    },
  },
};
