import { render, staticRenderFns } from "./GridBody.vue?vue&type=template&id=f3b5a488&scoped=true"
import script from "./GridBody.vue?vue&type=script&lang=js"
export * from "./GridBody.vue?vue&type=script&lang=js"
import style0 from "./GridBody.vue?vue&type=style&index=0&id=f3b5a488&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3b5a488",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/home/runner/work/tomasu-website/tomasu-website/app/components/nav/Link.vue').default,Body: require('/home/runner/work/tomasu-website/tomasu-website/app/components/text/Body.vue').default})
